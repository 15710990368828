@input-border-color: @surface-grey-tertiary;

.ant-input,
.ant-input-number {
  border-radius: @input-border-radius;
  border: 1px solid @input-border-color;
  &.blue {
    background-color: @surface-brand-rest-primary !important;
    border: 1px solid #3e58db;
    color: @white;
  }
  &.deep-blue {
    background-color: #1b2e8e !important;
    border: 1px solid #3e58db;
    color: @white;
  }
}

.ant-input-affix-wrapper,
.ant-input-number-affix-wrapper {
  border-radius: @input-border-radius;
  border: 1px solid @input-border-color;
  padding: 10px 8px;
  height: @input-total-height;

  &.blue {
    background-color: @surface-brand-rest-primary !important;
    border: 1px solid #3e58db;
    color: @white;
    .ant-input {
      background-color: @surface-brand-rest-primary !important;
      color: @white;
    }
  }
  &.deep-blue {
    background-color: #1b2e8e !important;
    border: 1px solid #3e58db;
    color: @white;
    .ant-input {
      background-color: #1b2e8e !important;
      color: @white;
    }
  }

  .ant-input {
    border-radius: 0;
  }
}

.ant-form-item-label {
  > label {
    text-transform: capitalize;
    .text-body-small;
  }
}

//errored number inputs are not centered vertically for some reason
.ant-input-number-affix-wrapper-has-feedback {
  .ant-input-number-input-wrap {
    input {
      padding-bottom: 20px;
    }
  }
}

.ant-input-affix-wrapper-disabled {
  background-color: @white;
}

// remove inner square on errored inputs
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:focus,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:focus,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input-focused,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused {
  box-shadow: none;
}

//remove the x icon in errored inputs
.ant-input-feedback-icon,
.ant-input-number-feedback-icon {
  display: none;
}

//remove the annoying autofill style
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}
