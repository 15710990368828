@import (reference) '/src/Style/customFonts.less';
//
.display-small-mixin {
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
}
.text-display-small {
  .display-small-mixin();
}
.text-display-small-bold {
  .display-small-mixin();
  font-weight: 700;
}

.text-display-small-extenda {
  .display-small-mixin();
  .custom-font;
}

.text-display-small-extenda-bold {
  .display-small-mixin();
  font-weight: 700;
  .custom-font;
}

//
.display-medium-mixin {
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
}
.text-display-medium {
  .display-medium-mixin();
}
.text-display-medium-bold {
  .display-medium-mixin();
  font-weight: 700;
}

.text-display-medium-extenda {
  .display-medium-mixin();
  .custom-font;
}

.text-display-medium-extenda-bold {
  .display-medium-mixin();
  font-weight: 700;
  .custom-font;
}
//
.display-large-mixin {
  font-size: 57px;
  line-height: 64px;
  font-weight: 400;
}
.text-display-large {
  .display-large-mixin();
}
.text-display-large-bold {
  .display-large-mixin();
  font-weight: 700;
}

.text-display-large-extenda {
  .display-large-mixin();
  .custom-font;
}

.text-display-large-extenda-bold {
  .display-large-mixin();
  font-weight: 700;
  .custom-font;
}
//

//
.headline-large-mixin {
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
}
.text-headline-large {
  .headline-large-mixin();
}
.text-headline-large-bold {
  .headline-large-mixin();
  font-weight: 700;
}

.text-headline-large-extenda {
  .headline-large-mixin();
  .custom-font;
}

.text-headline-large-extenda-bold {
  .headline-large-mixin();
  font-weight: 700;
  .custom-font;
}
//

//
.headline-medium-mixin {
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
}
.text-headline-medium {
  .headline-medium-mixin();
}
.text-headline-medium-bold {
  .headline-medium-mixin();
  font-weight: 700;
}

.text-headline-medium-extenda {
  .headline-medium-mixin();
  .custom-font;
}

.text-headline-medium-extenda-bold {
  .headline-medium-mixin();
  font-weight: 700;
  .custom-font;
}
//

//
.headline-small-mixin {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}
.text-headline-small {
  .headline-small-mixin();
}
.text-headline-small-bold {
  .headline-small-mixin();
  font-weight: 700;
}

.text-headline-small-extenda {
  .headline-small-mixin();
  .custom-font;
}

.text-headline-small-extenda-bold {
  .headline-small-mixin();
  font-weight: 700;
  .custom-font;
}
//

//
.title-large-mixin {
  font-size: 22px;
  line-height: 33px;
  font-weight: 400;
}

.title-large-alt-mixin {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.text-title-large-alt {
  .title-large-alt-mixin();
}

.text-title-large {
  .title-large-mixin();
}

.text-title-large-bold {
  .title-large-mixin();
  font-weight: 700;
}
//

//
.title-medium-mixin {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.text-title-medium {
  .title-medium-mixin();
}
.text-title-medium-bold {
  .title-medium-mixin();
  font-weight: 700;
}
//

//
.title-small-mixin {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.text-title-small {
  .title-small-mixin();
}
.text-title-small-bold {
  .title-small-mixin();
  font-weight: 700;
}
//

//
.label-large-mixin {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.text-label-large {
  .label-large-mixin();
}
.text-label-large-bold {
  .label-large-mixin();
  font-weight: 700;
}
//

//
.label-medium-mixin {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
.text-label-medium {
  .label-medium-mixin();
}
.text-label-medium-bold {
  .label-medium-mixin();
  font-weight: 700;
}
//

//
.label-small-mixin {
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
}
.text-label-small {
  .label-small-mixin();
}
.text-label-small-bold {
  .label-small-mixin();
  font-weight: 700;
}
//

//
.body-large-mixin {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.text-body-large {
  .body-large-mixin();
}
.text-body-large-bold {
  .body-large-mixin();
  font-weight: 700;
}
//

//
.body-medium-mixin {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.text-body-medium {
  .body-medium-mixin();
}
.text-body-medium-bold {
  .body-medium-mixin();
  font-weight: 700;
}
//

//
.body-small-mixin {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
.text-body-small {
  .body-small-mixin();
}
.text-body-small-bold {
  .body-small-mixin();
  font-weight: 700;
}
//

/// FONT COLORS
.text-primary {
  color: @surface-brand-rest-primary;
}

.text-greys-secondary {
  color: @element-greys-secondary;
}

.text-white {
  color: @white;
}

.text-red {
  color: @surface-brand-accent-rest-primary;
}
