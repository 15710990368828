.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-feedback-icon {
  color: #d7382d;
}
.ant-input-status-error .ant-input-prefix {
  color: #d7382d;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #d7382d;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #d7382d;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-feedback-icon {
  color: #d7382d;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #d7382d;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-feedback-icon {
  color: #d7382d;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #d7382d;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.QuizSection {
  background-color: #6f1610;
  padding: 105px 16px 135px;
}
.QuizSection__content {
  max-width: 1122px;
  width: 100%;
  margin: 0 auto;
}
.QuizSection__buttonContainer {
  max-width: 388px;
  width: 100%;
}
.QuizSection__row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.QuizSection__row--1 {
  margin-bottom: 104px;
}
.QuizSection__row--2 {
  padding: 48px 40px;
  border: 1px solid #d7382d;
}
.QuizSection__titleImage {
  width: 36%;
}
.QuizSection__title {
  color: #ffffff;
  font-family: Extenda-50, sans-serif;
  line-height: 1;
  letter-spacing: 0.5px;
  font-size: 220px;
  line-height: 185px;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.QuizSection__title--row1 {
  position: relative;
}
.QuizSection__title--row2 {
  font-size: 250px;
  line-height: 210px;
  text-align: center;
  position: relative;
  display: flex;
  gap: 135px;
}
.QuizSection__title--row2 img {
  position: absolute;
  left: 0;
  top: 7px;
  width: 110px;
  height: 167px;
}
.QuizSection__title--row2 .lastWord {
  display: inline-block;
  margin-left: 24px;
}
@media screen and (max-width: 500px) {
  .QuizSection__title--row2 .lastWord {
    margin-left: 0;
  }
}
.QuizSection__arrow {
  position: absolute;
  right: 0;
}
.QuizSection__subtitle {
  color: #fbfcfe;
  font-family: Extenda-50, sans-serif;
  line-height: 1;
  letter-spacing: 0.5px;
  line-height: 44px;
  font-size: 54px;
  margin: 0;
}
@media screen and (max-width: 1135px) {
  .QuizSection {
    padding: 27px 16px 95px;
  }
  .QuizSection__subtitle {
    font-size: 45px;
    line-height: 40px;
  }
  .QuizSection__title {
    font-size: 200px;
    line-height: 180px;
    justify-content: center;
  }
  .QuizSection__title--row2 {
    height: fit-content;
  }
  .QuizSection__title--row2 {
    font-size: 220px;
    line-height: 200px;
    height: fit-content;
  }
  .QuizSection__title--row2 img {
    height: 147px;
  }
  .QuizSection__arrow {
    top: -13px;
  }
}
@media screen and (max-width: 1000px) {
  .QuizSection__content {
    max-width: 768px;
  }
  .QuizSection__title {
    margin-bottom: 22px;
  }
  .QuizSection__title--row2 {
    transform: translateY(0);
  }
  .QuizSection__row--1 {
    flex-direction: column;
  }
  .QuizSection__row--2 {
    flex-direction: column;
  }
  .QuizSection__subtitle {
    margin-bottom: 20px;
    text-align: center;
  }
  .QuizSection__arrow {
    display: none;
  }
  .QuizSection__titleImage {
    width: 100%;
    max-width: 500px;
  }
}
@media screen and (max-width: 768px) {
  .QuizSection__content {
    max-width: 355px;
  }
  .QuizSection__title {
    font-size: 120px;
    line-height: 108px;
  }
  .QuizSection__title--row2 {
    font-size: 135px;
    line-height: 121px;
    transform: translateY(-5px);
    gap: 70px;
    text-align: right;
  }
  .QuizSection__title--row2 img {
    top: 4px;
    width: 112px;
    height: 89px;
  }
  .QuizSection__row--2 {
    flex-direction: column;
    padding: 40px;
  }
  .QuizSection__subtitle {
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
  }
}
@media screen and (max-width: 500px) {
  .QuizSection__title {
    line-height: 1;
    font-size: 80px;
  }
  .QuizSection__title--row2 {
    font-size: 85px;
  }
}
