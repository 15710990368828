.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-feedback-icon {
  color: #d7382d;
}
.ant-input-status-error .ant-input-prefix {
  color: #d7382d;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #d7382d;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #d7382d;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-feedback-icon {
  color: #d7382d;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #d7382d;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-feedback-icon {
  color: #d7382d;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #d7382d;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.MobileHeaderLayout {
  width: 100%;
  padding: 0 20px 0 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MobileHeaderLayout .nav-container {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100vh;
  padding: 20px 18px;
  background-color: #2139b0;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  transition: transform 0.3s ease-in-out;
}
.MobileHeaderLayout .nav-container--open {
  transform: translateX(-100%);
}
.MobileHeaderLayout .nav-container .separator {
  max-width: 315px;
  width: 100%;
  margin: 0 auto;
  height: 1px;
  background-color: #3e58db;
  border: none;
}
.MobileHeaderLayout .top-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.MobileHeaderLayout .nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  max-width: 315px;
  width: 100%;
  margin: 0 auto;
}
.MobileHeaderLayout .nav-item {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0 20px;
}
.MobileHeaderLayout .nav-item.selected-nav {
  border-bottom: 3px solid #e03529;
}
.MobileHeaderLayout .nav-item.text-body-large-bold {
  color: #ffffff;
  line-height: 16px;
}
.MobileHeaderLayout .connected-user {
  width: 100%;
}
.MobileHeaderLayout .none-connected-user {
  max-width: 315px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.MobileHeaderLayout .none-connected-user .login-button {
  padding: 10px 0;
  color: #ffffff;
}
