.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-feedback-icon {
  color: #d7382d;
}
.ant-input-status-error .ant-input-prefix {
  color: #d7382d;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #d7382d;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #d7382d;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-feedback-icon {
  color: #d7382d;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #d7382d;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-feedback-icon {
  color: #d7382d;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #d7382d;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.AccountTabs {
  height: 100%;
}
.AccountTabs__tab {
  background-color: #1b2e8e;
  color: #ffffff;
}
.AccountTabs__tab--active {
  background-color: #dbe0f8;
  color: #2139b0;
}
.AccountTabs__contentItem {
  background-color: #dbe0f8;
  height: 100%;
}
.AccountTabs__tabsCustom {
  height: 100%;
}
@media screen and (max-width: 500px) {
  .AccountTabs__tab {
    min-width: 0 !important;
    width: 354px;
  }
}
