@import (reference) '~antd/dist/antd.less';
@import (reference) '/src/Style/typography.less';

@notDesignSystemColor: #1b2e8e;

.ant-table-column-sorters {
  justify-content: space-between;
  width: 100%;
}

.table-action-rows {
  tbody tr {
    cursor: pointer;
  }
}

.ant-table {
  overflow-x: auto;
  // .Shadow-500-mixin();

  th.ant-table-cell {
    //header cell color
    background: @notDesignSystemColor !important;
    padding: 10px;
    height: 44px;
    color: @white;
    .text-body-large();

    //header text color
    .ant-table-column-title {
      color: @white;
      .text-body-large();
    }
  }

  td.ant-table-cell {
    padding: 5px 10px;
  }

  td.ant-table-cell {
    height: 34px;
  }

  .ant-table-tbody {
    .ant-table-row {
      // .text-footnote;

      //no hover effect on table rows in this project
      .ant-table-cell {
        background: transparent;
      }
    }
  }
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  //keep same th style when column has active sort direction
  background: @notDesignSystemColor;
}

td.ant-table-column-sort {
  //keep same td style when column has active sort direction
  background: none;
}

.ant-pagination {
  .ant-pagination-item {
    font-weight: bold;
    font-size: @font-size-sm;
    min-width: 50px;
    width: 50px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    a {
      color: @notDesignSystemColor;
    }

    &.ant-pagination-item-active {
      background-color: @notDesignSystemColor;

      a {
        color: @white;
      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    min-width: 40px;
    width: 40px;
    height: 48px;
    border-radius: 0;

    button {
      border: none;
      border-radius: 10px;
    }

    .anticon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-pagination-item-link {
      border-radius: 0;
    }
  }

  .ant-pagination-options {
    height: 48px;

    .ant-select-selector {
      border: none;
      display: flex;
      align-items: center;
    }

    .ant-pagination-options-size-changer,
    .ant-select-selector {
      height: 100%;

      .ant-select-selection-item {
        line-height: 3;
      }
    }
  }
}

//wierd line between head columns when sortable
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}
