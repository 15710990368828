.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-feedback-icon {
  color: #d7382d;
}
.ant-input-status-error .ant-input-prefix {
  color: #d7382d;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #d7382d;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #d7382d;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-feedback-icon {
  color: #d7382d;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #d7382d;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-feedback-icon {
  color: #d7382d;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #d7382d;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.SingleSelect {
  min-width: 120px;
  /* --- input styles --- */
  /* --- input styles --- */
  /* --- the selected element in the dropdown --- */
  /* --- the element selected in the dropdown --- */
  /* --- dropdown indicator on the right  --- */
  /* --- dropdown indicator on the right  --- */
}
.SingleSelect :hover {
  cursor: pointer;
}
.SingleSelect.blue-style {
  /* --- SPECIFIC TO CCAQ METIER --- */
}
.SingleSelect.blue-style div[class*='control'] {
  border: 1px solid #3e58db;
  border-radius: 0;
  background-color: #1b2e8e;
}
.SingleSelect.blue-style div[class*='control'] > div div[class*='placeholder'] {
  color: #ffffff;
}
.SingleSelect.blue-style div[class*='indicatorContainer'] {
  color: #ffffff;
}
.SingleSelect.blue-style div[class*='ValueContainer'] .dropdown-selected-element .selected-option-details .label-in-dropdown {
  color: #ffffff !important;
}
.SingleSelect.blue-style div[class*='Input'] input {
  color: #ffffff !important;
}
.SingleSelect.isSearchable-false div[class*='control'] input {
  position: absolute;
  z-index: -1;
}
.SingleSelect div[class*='control'] {
  border-radius: 0 !important;
  border: 1px solid #ededed;
  box-shadow: none;
  min-height: 48px;
  max-height: 48px;
  border-radius: 4px;
  transition: 300ms all;
}
.SingleSelect div[class*='control'] > div:first-child {
  pointer-events: none;
  flex-wrap: nowrap;
}
.SingleSelect div[class*='control'] > div {
  padding-left: 10px;
}
.SingleSelect div[class*='control'] > div div[class*='placeholder'] {
  font-family: Poppins;
  color: #8a8a8a;
  margin-left: 10px;
}
.SingleSelect div[class*='control']:hover {
  border: 1px solid #425bbd;
}
.SingleSelect.round div[class*='control'] {
  border-radius: 60px;
}
.SingleSelect.disabled div[class*='control'] {
  background-color: #f7f7f7;
}
.SingleSelect.disabled div[class*='control'] .dropdown-selected-element * {
  color: rgba(0, 0, 0, 0.25) !important;
}
.SingleSelect.disabled div[class*='control'] div[class*='indicatorContainer'] {
  color: #b3b3b3;
}
.SingleSelect.borderless > div[class*='control'] {
  border-color: transparent;
}
.SingleSelect .dropdown-selected-element {
  display: flex;
  align-items: center;
  grid-area: 1 / 1 / 2 / 3;
}
.SingleSelect .dropdown-selected-element > div {
  max-width: 100%;
}
.SingleSelect .dropdown-selected-element .selected-option-details {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
}
.SingleSelect .dropdown-selected-element .selected-option-details .label-in-dropdown {
  text-align: left;
}
.SingleSelect .dropdown-selected-element .with-icon {
  display: flex;
  align-items: center;
}
.SingleSelect .dropdown-selected-element .with-icon .anticon {
  margin-right: 10px;
}
.SingleSelect div[class*='indicatorContainer'] {
  margin-right: 5px;
  color: #2139b0;
  padding-left: 0;
}
/* --- menu styles */
div[class*='menu'] {
  z-index: 2;
  border-radius: 0;
}
div[class*='menu'] .single-dropdown-menu-option-container {
  width: 100%;
}
div[class*='menu'] .single-dropdown-menu-option-container .menuOptionDetails {
  width: 100%;
  display: flex;
  align-items: center;
}
div[class*='menu'] .single-dropdown-menu-option-container .menuOptionDetails .left-icon {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-left: 5px;
}
div[class*='menu'] .single-dropdown-menu-option-container .menuOptionDetails .dropdown-menu-option-label {
  display: flex;
  align-items: center;
}
div[class*='menu'] .single-dropdown-menu-option-container .menuOptionDetails .selected-checkmark {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 6px;
}
div[class*='menu'] .single-dropdown-menu-option-container .menuOptionDetails .selected-checkmark svg {
  width: 24px;
  height: 24px;
}
/* --- menu styles */
