.d-flex {
  display: flex;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.d-none {
  display: none;
}

.t-align-center {
  text-align: center;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.d-flex-align {
  display: flex;
  align-items: center;
}

.d-flex-justify {
  display: flex;
  justify-content: center;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

//paddings
.pl-40 {
  padding-left: 40px;
}

.pt-40 {
  padding-top: 40px;
}

//margins
.ml-auto {
  margin-left: auto;
}

.ml-40 {
  margin-left: 40px;
}

.mr-auto {
  margin-right: auto;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.bold {
  font-weight: 700;
}

//

.element-invisible {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
}
