.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-feedback-icon {
  color: #d7382d;
}
.ant-input-status-error .ant-input-prefix {
  color: #d7382d;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #d7382d;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #d7382d;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-feedback-icon {
  color: #faad14;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-feedback-icon {
  color: #d7382d;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #d7382d;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #d7382d;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #e36254;
  box-shadow: 0 0 0 2px rgba(215, 56, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-feedback-icon {
  color: #d7382d;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #d7382d;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-feedback-icon {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.Metiers {
  background-color: #ffffff;
  padding: 102px 16px;
}
.Metiers__content {
  max-width: 1130px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 162px;
}
.Metiers__row {
  width: 100%;
}
.Metiers__row--1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 88px;
  width: 100%;
  place-items: center;
}
.Metiers__row--2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Metiers__title {
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
}
.Metiers__titleRow {
  color: #2139b0;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Extenda-50, sans-serif;
  line-height: 1;
  letter-spacing: 0.5px;
  font-size: 175px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  /* 181.454px */
  text-transform: uppercase;
}
.Metiers__titleRow img {
  height: 149px;
  width: auto;
  margin-left: auto;
}
.Metiers__titleRow--1 {
  width: 100%;
}
.Metiers__titleRow--2 {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  position: relative;
}
.Metiers__titleRow--3 {
  font-size: 105px;
}
.Metiers__titleArrow {
  position: absolute;
  z-index: 10;
  top: 11.5%;
  left: 45%;
}
.Metiers__text {
  display: flex;
  flex-direction: column;
}
.Metiers__text p {
  margin: 0;
  width: 100%;
}
.Metiers__textTitle {
  margin: 0;
  height: 72px;
  font-family: Extenda-50, sans-serif;
  line-height: 1;
  letter-spacing: 0.5px;
  font-size: 40px;
  color: #2139b0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: flex-end;
}
.Metiers__textParagraph {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 40px;
  white-space: wrap;
}
.Metiers__textParagraph--accent {
  color: #e03529;
  padding-bottom: 20px;
}
.Metiers__categories {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 19px;
  margin-bottom: 60px;
  padding: 0;
  align-items: stretch;
}
.Metiers__categoryItem {
  display: inline-block;
}
.Metiers__buttonContainer {
  max-width: 555px;
  width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 1170px) {
  .Metiers__row--1 {
    gap: 40px;
  }
  .Metiers__categories {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 1050px) {
  .Metiers__content {
    gap: 100px;
  }
  .Metiers__row {
    max-width: 747px;
    margin: 0 auto;
  }
  .Metiers__row--1 {
    grid-template-columns: 1fr;
    gap: 52px;
  }
}
@media screen and (max-width: 800px) {
  .Metiers__categories {
    grid-template-columns: 1fr;
    margin-bottom: 20px;
  }
  .Metiers__buttonContainer {
    max-width: 364px;
  }
}
@media screen and (max-width: 540px) {
  .Metiers {
    padding-top: 81px;
    padding-bottom: 38px;
  }
  .Metiers__content {
    gap: 41px;
  }
  .Metiers__title {
    gap: 0;
    justify-self: center;
  }
  .Metiers__text {
    justify-self: center;
  }
  .Metiers__textTitle {
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    height: auto;
    margin-bottom: 20px;
  }
  .Metiers__textParagraph {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    gap: 0;
    padding: 0;
  }
  .Metiers__textParagraph--accent {
    padding: 40px 0 20px;
  }
  .Metiers__titleRow {
    font-size: 145px;
    line-height: auto;
  }
  .Metiers__titleRow--3 {
    font-size: 90px;
  }
  .Metiers__titleRow img {
    width: 124px;
    height: auto;
    margin-bottom: 15px;
  }
  .Metiers__titleArrow {
    left: 43%;
    top: 11%;
  }
}
@media screen and (max-width: 460px) {
  .Metiers__titleRow {
    font-size: 90px;
  }
  .Metiers__titleRow--2 {
    font-size: 90px;
  }
  .Metiers__titleRow--3 {
    font-size: 55px;
  }
  .Metiers__titleRow img {
    width: auto;
    height: 77px;
    margin-bottom: 0;
  }
  .Metiers__titleArrow {
    top: 0;
  }
}
